<template>
  <div class="row">
    <div class="col-12">
      <template>
        <component
          :is="component"
          :pidInfo.sync="pidInfo"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'process-flow-chart',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          title: '',
        }
      },
    },
  },
  data() {
    return {
      editable: true,
      pidInfo: {
        diagramClassCd: 'FLCHA', // 공정흐름도
        title: '공정흐름도',
      },
      component: null,
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.component = () => import('@/pages/psi/pidMaster.vue');
      this.pidInfo.title = this.$language('공정흐름도'); // 공정흐름도
    },
    reset() {
      Object.assign(this.$data.pidInfo, this.$options.data().pidInfo);
    },
  }
};
</script>